import React, { useEffect, useState, useCallback } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar';
import { useQueryRelationship } from "../../models/relationship/useQueryRelationship";
import { useQueryMe } from '../../models/me/useQueryMe';
import { useQueryMedallionsByUser } from '../../models/medallions/useQueryMedallionsByUser';
import { useUpdateProfile } from "../../models/profiles/useUpdateProfile";
import type { ProfileEntity } from '../../types/ProfileEntity';
import { FaTrash } from 'react-icons/fa';
import { useDeleteTribute } from '../../models/tributes/useDeleteTribute';
import type { TributeEntity } from '../../types/TributeEntity';

interface Field {
  id: number;
  value: string;
}

export const EditProfile: React.FC = () => {
  const { state } = useLocation();
  const [profile,] = useState<ProfileEntity>(state.profile);
  const { user } = useUser();
  const me = useQueryMe();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState<File>();
  const [gallery, setGallery] = useState<File[]>();
  const [name, setName] = useState<string>(profile.attributes.name);
  const [lastname, setLastname] = useState<string>(profile.attributes.lastname);
  const [title, setTitle] = useState<string>(profile.attributes.title);
  const [phrase, setPhrase] = useState<string>(profile.attributes.phrase);
  const [bio, setBio] = useState<string>(profile.attributes.bio);
  const [headline, setHeadline] = useState<string>(profile.attributes.headline);
  const [obituaryLink, setObituaryLink] = useState<string>(profile.attributes.obituary_link);
  const [legacyLink, setLegacyLink] = useState<string>(profile.attributes.legacy_link);
  //const [videoLink, setVideoLink] = useState<string>(profile.attributes.video_link);
  const [birthDate, setBirthDate] = useState<Date>(new Date(profile.attributes.birth_date));
  const [deathDate, setDeathDate] = useState<Date>(new Date(profile.attributes.death_date));
  const [relationship, setRelationship] = useState<number>(profile.attributes.relationship.data.id);
  const [medallion, setMedallion] = useState<number>(profile.attributes.medallion.data.id);
  const [, setDisabled] = useState(true);
  const [errorRequest, setErrorRequest] = useState('');
  const [graveAddress, setGraveAddress] = useState<string>(profile.attributes.grave_address);
  const [graveLocation, setGraveLocation] = useState<string>(profile.attributes.grave_location);
  const [graveLatitude, setGraveLatitude] = useState<string>(profile.attributes.grave_latitude);
  const [graveLongitude, setGraveLongitude] = useState<string>(profile.attributes.grave_longitude);
  const { responseData } = useQueryRelationship();
  const medallions = useQueryMedallionsByUser(me.responseData?.id);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [tributeToDelete, setTributeToDelete] = useState<TributeEntity | undefined>(undefined);
  const { handleDeleteTribute } = useDeleteTribute(tributeToDelete?.id);
  const [tributes, setTributes] = useState<TributeEntity[]>(profile.attributes.tributes.data);
  const [fields, setFields] = useState<Field[]>(profile.attributes.video_links as unknown as Field[]);

  const { handleUpdateProfile, loading, errorResponse, data } = useUpdateProfile({
    name,
    lastname,
    relationship,
    medallion,
    photo,
    birthDate,
    deathDate,
    title,
    headline,
    obituaryLink,
    legacyLink,
    //ideoLink,
    videoLinks: fields,
    bio,
    phrase,
    gallery,
    user: me.responseData?.id,
    graveAddress,
    graveLocation,
    graveLatitude,
    graveLongitude
  }, profile.id);

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const photoFile = event.target.files[0];
      setPhoto(photoFile);
    }
  };
  const handleGalleryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const galeryFiles = Array.from(event.target.files);
      setGallery(galeryFiles);
    }
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value);
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const handlePhraseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhrase(event.target.value);
  };
  const handleBioChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBio(event.target.value);
  };
  const handleHeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeadline(event.target.value);
  };
  const handleObituaryLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObituaryLink(event.target.value);
  };
  const handleLegacyLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLegacyLink(event.target.value);
  };
  /*   const handleVideoLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setVideoLink(event.target.value);
    }; */
  const handleBirthDateChange = (date: Date | null) => {
    if (date) {
      setBirthDate(date);
    }
  };
  const handleDeathDateChange = (date: Date | null) => {
    if (date) {
      setDeathDate(date);
    }
  };
  const handleRelationshipChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRelationship(Number(event.target.value));
  };
  const handleMedallionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMedallion(Number(event.target.value));
  };
  const handleGraveAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraveAddress(event.target.value);
  };
  const handleGraveLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraveLocation(event.target.value);
  };
  const handleGraveLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraveLatitude(event.target.value);
  };
  const handleGraveLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraveLongitude(event.target.value);
  };
  const validateFields = useCallback((): boolean => {
    if (!name || !lastname || !birthDate || !deathDate || !relationship || !medallion) {
      return true;
    }
    return false;
  }, [name, lastname, birthDate, deathDate, relationship, medallion]);
  const handleSubmit = async () => {
    if (gallery && gallery.length > 100) {
      setErrorRequest("La galería no pueden ser mas de 10 imágenes")
    } else if (validateFields()) {
      setErrorRequest("Los campos requeridos son obligatorios")
    } else {
      setErrorRequest('');
      try {
        await handleUpdateProfile();
      } catch (error) {
        setErrorRequest('Error al crear el perfil');
      }
    }
  };
  const handleSubmitDeletedTribute = async () => {
    try {
      if (tributeToDelete) {
        const deleteTribute = await handleDeleteTribute();
        const updateTributes = tributes?.filter(tribute => tribute.id !== deleteTribute?.id);
        setTributes(updateTributes)
        setShowDeleteModal(false);
      }
    } catch (error) {
      console.error(error)
    }
  };
  const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const values = [...fields];
    values[index].value = e.target.value;
    setFields(values);
  };
  const handleAddField = () => {
    const newField: Field = {
      id: fields.length + 1,
      value: ""
    };
    setFields([...fields, newField]);
  };
  const handleRemoveField = (index: number) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };


  useEffect(() => {
    if (gallery && gallery.length > 100) {
      setDisabled(true)
    } else if (validateFields()) {
      setDisabled(true)
    } else {
      setDisabled(false);
    }
  }, [validateFields, gallery]);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (data) {
      navigate('/dashboard');
    }
  }, [data, navigate]);

  return (
    <div className="flex">
      <Sidebar>
        <main className="flex flex-col flex-1 bg-white">
          <section className="relative py-8">
            <div className="lg:col-span-5">
              <div className="bg-white dark:bg-slate-900 p-12">
                <h3 className="mb-6 text-2xl leading-normal font-semibold">Editar Perfil</h3>
                <div className="grid lg:grid-cols-12 lg:gap-6">
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="name" className="font-semibold flex ">Nombre:</label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={name}
                      onChange={handleNameChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="lastname" className="font-semibold flex ">Apellido:</label>
                    <input
                      name="lastname"
                      id="lastname"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={lastname}
                      onChange={handleLastnameChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="birthDate" className="font-semibold flex ">Fecha de Nacimiento:</label>
                    <input
                      name="birthDate"
                      id="birthDate"
                      type="date"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      onChange={(e) => handleBirthDateChange(new Date(e.target.value))}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="deathDate" className="font-semibold flex ">Fecha de Muerte:</label>
                    <input
                      name="deathDate"
                      id="deathDate"
                      type="date"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      onChange={(e) => handleDeathDateChange(new Date(e.target.value))}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="title" className="font-semibold">Título:</label>
                    <input
                      name="title"
                      id="title"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      placeholder="Ejemplo: Jr - Sr"
                      value={title}
                      onChange={handleTitleChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="relationship" className="font-semibold flex ">Relación:</label>
                    <select
                      name="relationship"
                      id="relationship"
                      className="form-select w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={relationship}
                      onChange={handleRelationshipChange}
                    >
                      <option value="">Seleccione una relación</option>
                      {responseData?.map((relationship) => (
                        <option key={relationship.id} value={relationship.id}>
                          {relationship.attributes.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="photo" className="font-semibold flex ">Foto de perfil:</label>
                    <input
                      name="photo"
                      id="photo"
                      type="file"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      onChange={handlePhotoChange}
                    />
                  </div>
                </div>
                <h3 className="my-6 text-2xl leading-normal font-semibold">Editar información conmemorativa</h3>
                <div className="grid lg:grid-cols-12 lg:gap-6">
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="gallery" className="font-semibold">Galeria de imagenes:</label>
                    <input
                      name="gallery"
                      id="gallery"
                      type="file"
                      multiple
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      onChange={handleGalleryChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="headline" className="font-semibold">Cabecera:</label>
                    <input
                      name="headline"
                      id="headline"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      placeholder="Ejemplo: En memoria de..."
                      value={headline}
                      onChange={handleHeadlineChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="obituary" className="font-semibold">Link Obituario:</label>
                    <input
                      name="obituaryLink"
                      id="obituaryLink"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={obituaryLink}
                      onChange={handleObituaryLinkChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="obituary" className="font-semibold">Link Legado:</label>
                    <input
                      name="legacyLink"
                      id="legacyLink"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={legacyLink}
                      onChange={handleLegacyLinkChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="obituary" className="font-semibold">Link Vídeos Youtube:</label>
                    {/*   <input
                      name="videoLink"
                      id="videoLink"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={videoLink}
                      onChange={handleVideoLinkChange}
                    /> */}
                    {fields.map((field, index) => (
                      <div key={field.id} className="flex items-center mb-2">
                        <input
                          name="videoLinks"
                          id="videoLinks"
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                          value={field.value}
                          onChange={(e) => handleChange(index, e)}
                        />
                        {fields.length > 1 && (
                          <button
                            className="py-2 px-5 ml-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-red-400 hover:bg-red-500 border-red-400 hover:border-red-500 text-white rounded-md"
                            onClick={() => handleRemoveField(index)}
                          >
                            X
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md"
                      onClick={handleAddField}
                    >
                      Otro vídeo
                    </button>
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="phrase" className="font-semibold">Frase o Poema:</label>
                    <input
                      name="phrase"
                      id="phrase"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={phrase}
                      onChange={handlePhraseChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="medallion" className="font-semibold flex">Medallón QR:</label>
                    <select
                      name="medallion"
                      id="medallion"
                      className="form-select w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={medallion}
                      onChange={handleMedallionChange}
                    >
                      <option value="">Seleccione un medallón</option>
                      {medallions.responseData?.map((medallion) => (
                        medallion.attributes.profiles.data?.length < 3 && (
                          <option key={medallion.id} value={medallion.id}>
                            {medallion.attributes.slug}
                          </option>
                        )
                      ))}
                    </select>
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="bio" className="font-semibold">Biografía:</label>
                    <textarea
                      name="bio"
                      id="bio"
                      className="form-textarea w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={bio}
                      onChange={handleBioChange}
                      rows={4}
                      placeholder="Escribe algo acerca del fallecido..."
                    />
                  </div>
                </div>
                <h3 className="my-6 text-2xl leading-normal font-semibold">Editar información del sepulcro</h3>
                <div className="grid lg:grid-cols-12 lg:gap-6">
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="graveLongitude" className="font-semibold">Nombre Cementerio:</label>
                    <input
                      name="graveLongitude"
                      id="graveLongitude"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={graveLongitude}
                      onChange={handleGraveLongitudeChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="graveAddress" className="font-semibold">Dirección Cementerio:</label>
                    <input
                      name="graveAddress"
                      id="graveAddress"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      placeholder="Ejemplo: CAPILLA OSARIO LA RESURECCIÓN"
                      value={graveAddress}
                      onChange={handleGraveAddressChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="graveLocation" className="font-semibold">Dirección Sepulcro:</label>
                    <input
                      name="graveLocation"
                      id="graveLocation"
                      type="text"
                      placeholder='Ejemplo: E02 Grupo209 -Lote 03'
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={graveLocation}
                      onChange={handleGraveLocationChange}
                    />
                  </div>

                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="graveLatitude" className="font-semibold">Link Mapa:</label>
                    <input
                      name="graveLatitude"
                      id="graveLatitude"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={graveLatitude}
                      onChange={handleGraveLatitudeChange}
                    />
                  </div>

                </div>
                {errorRequest &&
                  <div className="lg:col-span-6 mb-5">
                    <label className="font-semibold text-red-600">{errorRequest}</label>
                  </div>
                }
                {errorResponse &&
                  <div className="lg:col-span-6 mb-5">
                    <label className="font-semibold text-red-600">{errorResponse.message}</label>
                  </div>
                }
                <button
                  disabled={loading}
                  onClick={handleSubmit}
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md"
                >
                  {loading ? "Enviando" : "Enviar"}
                </button>
              </div>
            </div>
          </section>
          {tributes && tributes.length >= 1 &&
            <section className="relative py-8">
              <div className="bg-white dark:bg-slate-900 p-12">
                <h3 className="mb-6 text-2xl leading-normal font-semibold">Editar Tributos</h3>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                  {tributes?.map((tribute, index) => {
                    return <div className="bg-white dark:bg-slate-900 p-4 rounded-md shadow dark:shadow-gray-700 angel-card" key={index}>
                      <div className="flex justify-between profiles-center">
                        <span className="text-slate-400">{tribute.attributes.name}</span>
                        <span className="text-slate-400">{tribute.attributes.email}</span>
                        <div className="flex profiles-center space-x-2 self-start">
                          <button onClick={() => { setTributeToDelete(tribute); setShowDeleteModal(true); }} className="focus:outline-none rounded-md p-2 bg-red-500 text-white ml-2">
                            <FaTrash />
                          </button>
                        </div>
                      </div>
                      <div className="mt-5 flex justify-between profiles-center">
                        <span className="flex profiles-center">
                          <p className="ms-1 text-slate-400">{tribute.attributes.comment}</p>
                        </span>
                      </div>
                    </div>
                  })}
                </div>
              </div>
            </section>

          }
          {showDeleteModal && (
            <div className="fixed top-0 left-0 w-[80%] h-full bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-md shadow-lg animate-fade-in">
                <p className="text-lg font-semibold mb-4">¿Estás seguro de que deseas eliminar este tributo?</p>
                <div className="flex justify-end space-x-4">
                  <button disabled={loading} onClick={() => setShowDeleteModal(false)} className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md">Cancelar</button>
                  <button disabled={loading} onClick={handleSubmitDeletedTribute} className="px-4 py-2 bg-amber-400 text-white rounded-md">Eliminar</button>
                </div>
              </div>
            </div>
          )}
        </main>
      </Sidebar>
    </div>
  );
};

