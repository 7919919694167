import React, { useCallback, useEffect, useState } from "react";
import { Navbar } from '../../components/Navbar';
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import contactImg from "../../assets/images/contact.svg"
import { FiHexagon, FiPhone, FiMail, FiMapPin } from "../../assets/icons/vander"
import { useQueryConfig } from "../../models/config/useQueryConfig";
import { useCreateContact } from "../../models/contacts/useCreateContact";

export const Contact: React.FC = () => {
  const { responseData } = useQueryConfig();
  const [question, setQuestion] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [errorRequest, setErrorRequest] = useState('');
  const [, setDisabled] = useState(true);
  const createContact = useCreateContact({
    name,
    question,
    comment,
    email
  });

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };

  const validateFields = useCallback((): boolean => {
    if (!name || !email || !comment || !question) {
      return true;
    }
    return false;
  }, [name, email, comment, question]);

  const handleSubmit = async () => {
    if (validateFields()) {
      setErrorRequest("Los nombre, el email, la pregunta y mensaje son obligatorios.")
    } else {
      setErrorRequest('');
      try {
        await createContact.handleCreateContact();
        setName("");
        setEmail("");
        setComment("");
        setQuestion("");
      } catch (error) {
        setErrorRequest('Error al crear el tributo');
      }
    }

  };

  useEffect(() => {
    if (validateFields()) {
      setDisabled(true)
    } else {
      setDisabled(false);
    }
  }, [validateFields]);

  return (
    <>
      <Navbar />
      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img src={contactImg} alt="" />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-semibold">¡Ponte en contacto!</h3>

                  <p className="mb-0" id="error-msg"></p>
                  <div id="simple-msg"></div>
                  <div className="grid lg:grid-cols-12 lg:gap-6">
                    <div className="lg:col-span-6 mb-5">
                      <label htmlFor="name" className="font-semibold">Tu nombre:</label>
                      <input value={name} onChange={handleNameChange} name="name" id="name" type="text" className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Nombre:" />
                    </div>

                    <div className="lg:col-span-6 mb-5">
                      <label htmlFor="email" className="font-semibold">Tu correo electrónico:</label>
                      <input value={email} onChange={handleEmailChange} name="email" id="email" type="email" className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Correo electrónico:" />
                    </div>
                  </div>

                  <div className="grid grid-cols-1">
                    <div className="mb-5">
                      <label htmlFor="subject" className="font-semibold">Tu pregunta:</label>
                      <input value={question} onChange={handleQuestionChange} name="subject" id="subject" className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Asunto:" />
                    </div>

                    <div className="mb-5">
                      <label htmlFor="comments" className="font-semibold">Tu comentario:</label>
                      <textarea value={comment} onChange={handleCommentChange} name="comments" id="comments" className="form-input w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2 h-40" placeholder="Mensaje:"></textarea>
                    </div>
                  </div>
                  {errorRequest &&
                    <div className="lg:col-span-6 mb-5">
                      <label className="font-semibold text-red-600">{errorRequest}</label>
                    </div>
                  }
                  {createContact.data &&
                    <div className="lg:col-span-6 mb-5">
                      <label className="font-semibold text-green-600">Mensaje enviado con éxito</label>
                    </div>
                  }
                  <button
                    onClick={handleSubmit}
                    disabled={createContact.loading}
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md"
                  >
                    {createContact.loading ? "Enviando" : "Enviar mensaje"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                  <FiPhone />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Teléfono</h5>
                <p className="text-slate-400 mt-3">Contacta directamente a nuestro personal de soporte.</p>

                <div className="mt-5">
                  <Link to="tel:+152534-468-854" className="hover:text-amber-400">{responseData?.attributes.phone}</Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                  <FiMail />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Correo electrónico</h5>
                <p className="text-slate-400 mt-3">Dejanos tu mensaje y con gusto lo leeremos.</p>

                <div className="mt-5">
                  <Link to="mailto:contact@example.com" className="hover:text-amber-400">{responseData?.attributes.email}</Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                  <FiMapPin />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Ubicación</h5>
                <p className="text-slate-400 mt-3">{responseData?.attributes.address}</p>

                <div className="mt-5">
                  <Link target="_blank" to="https://maps.app.goo.gl/b13dooBwvvywnujR9"
                    data-type="iframe" className="video-play-icon read-more
                        lightbox hover:text-amber-400">Ver en Google Maps</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
