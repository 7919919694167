import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from '../../assets/icons/vander'

export const Faq: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(1)
  const accordionData = [
    {
      id: 1,
      title: '¿Cuáles son las dimensiones?',
      desc: 'Cada medallón QR es de 5x5 cm.'
    },
    {
      id: 2,
      title: '¿Qué está incluido?',
      desc: 'Cada pedido contiene un medallón QR única y resistente a la intemperie, hecha de aluminio de alta calidad, así como una página conmemorativa adjunta.'
    },
    {
      id: 3,
      title: '¿Hay alguna tarifa continua para el servicio del medallón QR?',
      desc: 'No, es un pago único por un servicio de por vida sin costo adicional.'
    },
    {
      id: 4,
      title: '¿Puedo usar el medallón QR para mi mascota?',
      desc: 'Sí, el medallón QR también es maravillosa para crear una página conmemorativa para mascotas fallecidas. Para que puedas mantener digna la memoria de tu fiel compañero.'
    },
  ]

  return (
    <>
      <div className="relative md:mt-24 mt-16">
        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center md:gap-[30px]">
          <div className="lg:col-span-4 md:mb-0 mb-8">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Preguntas frecuentes y respuestas</h3>

            <p className="text-slate-400 max-w-xl mx-auto mb-6">Aquí encontrarás las preguntas más frecuentes. Si aún tiene alguna pregunta, puede enviarnos un mensaje a través de nuestro formulario de contacto.</p>

            <Link to="/contact" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-amber-400 border-gray-100 dark:border-gray-800 hover:border-amber-400 dark:hover:border-amber-400 text-slate-900 dark:text-white hover:text-white rounded-md">Contacto</Link>
          </div>

          <div className="lg:col-span-8 md:mt-0 mt-8">
            {accordionData.map((item, index) => {
              return (
                <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4" key={index}>
                  <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                    <button type="button" onClick={() => setActiveIndex(item.id)} className={`${activeIndex === item.id ? "bg-gray-50 dark:bg-slate-800 text-amber-400" : ""} flex justify-between items-center p-5 w-full font-medium text-start`}>
                      <span>{item.title}</span>
                      <MdKeyboardArrowDown className={`${activeIndex === item.id ? "rotate-180" : ""} w-4 h-4 shrink-0`} />
                    </button>
                  </h2>
                  <div className={activeIndex === item.id ? "" : "hidden"}>
                    <div className="p-5">
                      <p className="text-slate-400 dark:text-gray-400">{item.desc}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}